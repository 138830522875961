import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';
import './App.scss';

function App() {
  return (
    <div className="App">
      <Navbar className="justify-content-center d-flex flex-column top-bar mb-lg-5">
        <h1 className="text-white">Welcome to...</h1>
        <Navbar.Brand href="/Nevaland">
          <img
            src={`${process.env.PUBLIC_URL}/nevaland_logo.png`}
            width="400"
            height="180"
            className="d-inline-block align-top"
            alt="Nevaland logo"
          />
        </Navbar.Brand>
      </Navbar>
      <main>
        <div className="justify-content-center align-items-center d-flex flex-column">
          <h4 className="text-white pr-lg-5 pl-lg-5 mr-lg-5 ml-lg-5 mb-lg-5 text-center">
            Welcome to the Nevaland Productions where we discuss many different
            topics from the NBA and other sports to Rap and Hip-Hop. We also
            talk about any current topics or topics that we find interesting. Be
            sure to join in on the conversation by Tweeting at us or by finding
            us on Instagram!
          </h4>
          <h5 className="text-white mb-lg-2">Podcast links:</h5>
          <div className="mb-lg-5">
            <Button
              href="https://anchor.fm/nevalandpod"
              className="mr-sm-1 ml-sm-1"
              variant="outline-primary"
            >
              <div className="d-flex align-items-center">
                Listen on{' '}
                <img
                  src={`${process.env.PUBLIC_URL}/anchor_icon.png`}
                  width="30"
                  height="30"
                  className="d-inline-block align-top ml-sm-1"
                  alt="Anchor logo"
                />
              </div>
            </Button>
            <Button
              href="https://podcasts.apple.com/us/podcast/the-nevaland-podcast/id1453417572"
              className="mr-sm-1 ml-sm-1"
              variant="outline-light"
            >
              <div className="d-flex align-items-center">
                Listen on{' '}
                <img
                  src={`${process.env.PUBLIC_URL}/apple_icon.png`}
                  width="30"
                  height="30"
                  className="d-inline-block align-top ml-sm-1"
                  alt="Apple logo"
                />
              </div>
            </Button>
            <Button
              href="https://open.spotify.com/show/42bDnyh2IiXGdbVWzyNMJH?si=w1yRQaYwQhGQ-on6MdXHLQ"
              className="mr-sm-1 ml-sm-1"
              variant="outline-success"
            >
              <div className="d-flex align-items-center">
                Listen on{' '}
                <img
                  src={`${process.env.PUBLIC_URL}/spotify_icon.png`}
                  width="30"
                  height="30"
                  className="d-inline-block align-top ml-sm-1"
                  alt="Spotify logo"
                />
              </div>
            </Button>
          </div>
          <h5 className="text-white mb-lg-2">Socials:</h5>
          <div className="d-flex flex-row">
            <SocialIcon
              className="mr-sm-1 ml-sm-1"
              url="http://twitter.com/asharma210"
            />
            <SocialIcon
              className="mr-sm-1 ml-sm-1"
              url="https://www.youtube.com/channel/UCyRQabvLb8BX7CaJ5LSWvMA"
            />
            <SocialIcon
              className="mr-sm-1 ml-sm-1"
              url="https://www.instagram.com/asharma210/"
            />
            <SocialIcon
              className="mr-sm-1 ml-sm-1"
              url="https://podcasts.google.com/?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy85MDMyZDgwL3BvZGNhc3QvcnNz"
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
